import { QUESTIONNAIRE_STATUSES } from '@main/shared/types';
import { getQuestionnaireType, QUESTIONNAIRE_TYPES } from '@main/shared/utils';
import {
  createColumnHelper,
  Table,
  TableEmptyState,
  useDrawer,
  useTableFiltersQuery,
  useTableSearchQuery,
  useTableSortQuery,
} from '@main/ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { GetVendorQuestionnairesTableQuery } from './questionnaires-table.generated';
import { useGetVendorQuestionnairesTableSubscription } from './questionnaires-table.subs';

type VendorQuestionnaires = GetVendorQuestionnairesTableQuery['vendor_questionnaires'];
type VendorQuestionnaire = VendorQuestionnaires[number];

export const VendorQuestionnairesTable = () => {
  const { t } = useTranslation();
  const { isLoading, data } = useGetVendorQuestionnairesTableSubscription();
  const questionnaires = data?.vendor_questionnaires ?? [];

  const columns = useVendorQuestionnairesTableColumns();
  const [columnFilters, setColumnFilters] = useTableFiltersQuery({
    columns,
    searchParam: 'questionnairesFilter',
  });
  const [globalFilter, setGlobalFilter] = useTableSearchQuery({ searchParam: 'search' });
  const [sorting, setSorting] = useTableSortQuery({ searchParam: 'questionnairesSort' });

  const tableItemName = useMemo(() => {
    return {
      singular: t('vendors.questionnaires.heading').toLowerCase(),
      plural: t('vendors.questionnaires.heading').toLowerCase(),
    };
  }, [t]);

  return (
    <Table
      minW="900px"
      entity="vendor-questionnaire"
      data={questionnaires}
      isLoading={isLoading}
      columns={columns}
      itemName={tableItemName}
      pageSize={15}
      renderEmptyState={(props) => <TableEmptyState {...props} />}
      columnFilters={columnFilters}
      onColumnFiltersChange={setColumnFilters}
      globalFilter={globalFilter}
      onGlobalFilterChange={setGlobalFilter}
      sorting={sorting}
      onSortingChange={setSorting}
    />
  );
};

const useVendorQuestionnairesTableColumns = () => {
  const { t } = useTranslation();
  const drawer = useDrawer();

  return useMemo(() => {
    const columnHelper = createColumnHelper<VendorQuestionnaire>();

    return [
      columnHelper.columns.status({
        id: 'status',
        enableGlobalFilter: true,
        enableColumnFilter: true,
        enableSorting: true,
        header: t('vendors.questionnaires.tableColumns.status'),
        accessorFn: (data) => {
          const statusMeta = QUESTIONNAIRE_STATUSES[data.status];

          return {
            value: t(statusMeta.value),
            colorScheme: statusMeta.colorScheme,
          };
        },
        size: 120,
      }),
      columnHelper.columns.text({
        id: 'name',
        enableGlobalFilter: true,
        enableColumnFilter: true,
        enableSorting: true,
        header: t('vendors.questionnaires.tableColumns.name'),
        accessorFn: (data) => data.questionnaire?.name,
        meta: {
          cell: {
            onClick: (cell) =>
              drawer.open({ entity: 'vendor-questionnaire', entityId: cell.row.original.id }),
          },
        },
      }),
      columnHelper.columns.tag({
        id: 'type',
        enableGlobalFilter: true,
        enableColumnFilter: true,
        enableSorting: true,
        header: t('vendors.questionnaires.tableColumns.type'),
        accessorFn: (data) => {
          const typeMeta = QUESTIONNAIRE_TYPES[getQuestionnaireType(data.questionnaire)];

          return {
            value: t(typeMeta.value),
            colorScheme: typeMeta.colorScheme,
          };
        },
        size: 120,
      }),
      columnHelper.columns.date({
        id: 'dueDate',
        enableColumnFilter: true,
        enableSorting: true,
        header: t('vendors.questionnaires.tableColumns.dueDate'),
        accessorFn: (data) => data.due_date,
        size: 180,
      }),
      columnHelper.columns.avatar({
        id: 'requestedBy',
        enableGlobalFilter: true,
        enableColumnFilter: true,
        enableSorting: true,
        header: t('vendors.questionnaires.tableColumns.requestedBy'),
        variant: 'detailed',
        accessorFn: (data) => {
          return {
            id: data?.sent_by_user?.id ?? '',
            displayName: data?.sent_by_user?.displayName ?? 'Unknown',
          };
        },
        size: 220,
      }),
    ];
  }, [t, drawer]);
};
